import React from 'react'
import { ArrowRightCircle } from "react-bootstrap-icons";
// import { Link } from "react-router-dom";

const ButtonCoach = () => {
  return (
    <div>
        <a href="https://wa.me/3512035657?text=Hola! Quiero%20asesoramiento">
        <div className="container-button-banner-landing">
        <button className="button-connect-2-landing">
          Quiero asesoramiento <ArrowRightCircle size={25} className="icon-service"/>
        </button>
      </div>
      </a>
    </div>
  )
}

export default ButtonCoach