import { useState, useEffect} from "react";
import { ArrowRightCircle } from "react-bootstrap-icons";
import "../../../styles/BannerService.css";
import "../../../styles/Text.css"
import Image1 from "../../../assets/img/Service.jpg";
import Image2 from "../../../assets/img/Service2.jpg";
import Image3 from "../../../assets/img/Service3.jpg";


const imageList = [Image1, Image2, Image3];
const textList = [
  "Potencia tu negocio con una landing page. Convierte visitantes en clientes, comunica tu mensaje de manera efectiva y destaca en la web.",
  "Transforma tu visión en realidad con nuestras aplicaciones web personalizadas y autogestionables. Diseñadas 100% a medida, te ofrecen control total sobre tus procesos. Simplifica la gestión, potencia tu eficiencia y lleva tu negocio al siguiente nivel. Descubre el poder de la personalización y la autonomía hoy mismo.",
  "Potencia tu presencia en línea con el marketing digital y SEO. Aumenta la visibilidad, atrae a tu audiencia objetivo y eleva tu marca a nuevos niveles de éxito. Descubre los beneficios de una estrategia digital sólida: más visibilidad, más clientes, más éxito.",
];
const textList2 = ["SERVICIOS", "SERVICIOS", "SERVICIOS"];
const textList3 = [
  "Desarrollo de landing page",
  "Desarrollo de aplicaciones o sistemas web",
  "Marketing Digital y posicionamiento SEO",
];

const rutes = [
  <h6 className="h6-banner-1">Mas información sobre landing pages</h6>,
  <h6 className="h6-banner-1">
    Mas información sobre aplicaciones y sistemas web
  </h6>,
  <h6 className="h6-banner-1">Mas información sobre Marketing digital</h6>,
];
const transitionDuration = 5000;

export const BannerService = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setNextImageIndex((currentImageIndex + 1) % imageList.length);
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
    }, transitionDuration);

    return () => {
      clearInterval(interval);
    };
  }, [currentImageIndex]);


  const handleButtonClick = (index) => {
    if (index === 0) {
      const bannerLandingElement = document.getElementById("banner-landing");

      if (bannerLandingElement) {
        bannerLandingElement.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (index === 1) {
      const bannerLandingElement = document.getElementById("banner-sistem");

      if (bannerLandingElement) {
        bannerLandingElement.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (index === 2) {
      const bannerLandingElement = document.getElementById("banner-marketing");

      if (bannerLandingElement) {
        bannerLandingElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  return (
    <section className="banner-service">
      <div className="container-banner-service">
        <hr className="hr-white-2" />
        <span className="tagline-service">{textList2[currentImageIndex]}</span>

        <h1>
          <span>{textList3[currentImageIndex]}</span>
        </h1>
        <p className="p-text-banner-service">{textList[currentImageIndex]}</p>
        <div className="container-button-banner-service">
          <button
            className="button-connect-2-service"
            onClick={() => handleButtonClick(currentImageIndex)}
          >
            {rutes[currentImageIndex]} <ArrowRightCircle size={25} />
          </button>
        </div>
      </div>
      <div className="image-transition-container-service">
        <div className="background-gradient-service"></div>
        <div className="image-wrapper-service">
          {imageList.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Metacode ${index}`}
              className="transition-image-service"
              style={{
                opacity:
                  index === currentImageIndex || index === nextImageIndex
                    ? 1
                    : 0,
              }}
            />
          ))}
        </div>
      </div>
    </section>
    
  );
};

export default BannerService;
