import React from "react";
import "../../styles/Text.css";
import "../../styles/BannerService.css";
import Image from "../../assets/img/IconBarber.png";
import { ArrowRightCircle } from "react-bootstrap-icons";
// import { Link } from "react-router-dom";

const BarberBody = () => {
  return (
    <div id="banner-barber" className="banner-landing mt-5"> 
      <hr className="hr-white-2" />
      <div className="h1-tittle">
        <h1>En metacode desarrollamos una aplicación web para barberías</h1>
      </div>
      <div className="tagline-landing">
        <p>¿En que ayuda esta aplicación web?</p>
      </div>
      <div className="p-text-image">
        <p className="p-text-banner-landing">
          Con BarberMap vas a poder gestionar todos tus turnos en linea y olvidarte de agendar. Obtene estadisticas de tu barbería, control de caja, posicionamiento en el mapa, rankings y mucho más!
        </p>
        <img src={Image} className="image-banner-service" alt="banner"/>
      </div>
      <a href="https://barbermap.com.ar/#/salon-management-software">
      <div className="container-button-banner-landing">
        <button className="button-connect-2-landing">
          Quiero más información <ArrowRightCircle size={25} className="icon-service"/>
        </button>
      </div>
      </a>
      <hr className="hr-white" />
      
    </div>
  );
};

export default BarberBody;
