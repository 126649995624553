import React from "react";
import "../../../styles/BannerInnovation.css";
import Image from "../../../assets/img/BannerInnovation2.jpg"
import Image2 from "../../../assets/img/BannerInnovation4.jpg"
import Image3 from "../../../assets/img/BannerInnovation3.jpg"
const BannerInnoBody = () => {
  return (
    <div className="container-innobody">

      <div className="banner-innobody container">
        <hr className='hr-white-3 container' />
        <h2>Automatización de Procesos Empresariales (BPM)</h2>
        <div className="banner-innobody-image-p">
          <img src={Image} alt="banner" />
          <p>Implementar sistemas web con herramientas de automatización que optimicen flujos de trabajo,
            reduzcan la carga manual y mejoren la eficiencia operativa.
          </p>
        </div>
      </div>

      <div className="banner-innobody container">
        <hr className='hr-white-3 container' />
        <h2>Inteligencia Artificial (IA) y Machine Learning</h2>
        <div className="banner-innobody-image-p">
          <img src={Image2} alt="banner" />
          <p>Integrar capacidades de IA y aprendizaje automático para personalizar la experiencia del usuario,
            automatizar procesos
            y ofrecer recomendaciones inteligentes.
          </p>
        </div>
      </div>

      <div className="banner-innobody container">
        <hr className='hr-white-3 container' />
        <h2>Experiencia del Usuario Mejorada</h2>
        <div className="banner-innobody-image-p">
          <img src={Image3} alt="banner" />
          <p>Innovar en el diseño de interfaz, navegación intuitiva, y experiencias de usuario inmersivas
            para ofrecer aplicaciones web altamente atractivas y funcionales.
          </p>
        </div>
      </div>
      <hr className='hr-white-3 container' />

    </div>
  );
};

export default BannerInnoBody;
