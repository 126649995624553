import React from "react";
import Image from "../../assets/img/Technologies.jpg";

const TechnologiesBody = () => {
  return (
    <div
      id="banner-technologies"
      className="banner-experience"
    >
      <hr className="hr-white-2" />
      <div className="h1-tittle">
        <h1>Ecosistema Tecnológico</h1>
      </div>
      <div className="tagline-experience">
        <p>Potenciando Soluciones con Tecnologías de Vanguardia</p>
      </div>
      <div className='p-text-image-experience'>
            <p className='p-text-banner-experience'> 
            Descubre el sólido fundamento tecnológico que impulsa nuestras soluciones a la vanguardia. En Metacode, empleamos un conjunto diverso de tecnologías líderes para ofrecer desarrollo web y aplicaciones de calidad. Desde la agilidad de ReactJS hasta la potencia de NodeJs, la robustez de SQL Server, la versatilidad de Python, la eficiencia de Nest y la base sólida de JavaScript, nuestro ecosistema tecnológico está diseñado para superar expectativas y brindar experiencias excepcionales a nuestros clientes. Explora cómo estas herramientas trabajan en armonía para dar vida a ideas innovadoras y soluciones personalizadas.
            </p>
            <img src={Image} className='img-technologies' alt='banner'/>
        </div>
        <button className="button-connect-2-landing container">
        Descubre cómo transformamos ideas en realidad
        </button>
        <hr className="hr-white-3" />
    </div>
  );
};

export default TechnologiesBody;
