import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/Cards.css";
import "../../styles/Buttons.css";
import { Link } from "react-router-dom";

const CardIndex = () => {
  return (
    <div className="container">
      <div className="row">
        {/* Tarjeta grande */}
        <hr className="hr-white" />
        <div className="col-lg-6">
          <div className="card mb-4 card-big">
            <div className="card-body">
              <h5 className="card-title">Mas allá del codigo</h5>
              <p className="card-text">Servicios</p>
              <Link to="/services">
                <button className="btn-card">Ver Servicios</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6">
          <div className="card mb-4 card-small-3">
            <div className="card-body">
              <h5 className="card-title">Software para Barberías</h5>
              <p className="card-text">
                Optimiza tu barbería y deja de perder tiempo
              </p>
              <Link to="">
                <button className="btn-card-small-2">
                  Quiero obtener BarberMap
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6">
          <div className="card mb-4 card-small-4">
            <div className="card-body">
              <h5 className="card-title">Industrias Atendidas</h5>
              <p className="card-text">
                Comprometidos en las industrias que servimos
              </p>
              <Link to="/industries-served">
                <button className="btn-card-small-2">Descubre Más</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6">
          <div className="card mb-4 card-small-1">
            <div className="card-body-small">
              <h5 className="card-title">Innovación</h5>
              <p className="card-text">Transformando el Presente</p>
              <Link to="/innovation">
                <button className="btn-card-small">Más información</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6">
          <div className="card mb-4 card-small-2">
            <div className="card-body">
              <h5 className="card-title">Visión y Misión</h5>
              <Link to="">
                <button className="btn-card-small">Ver más</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="card mb-4 card-big-2">
            <div className="card-body">
              <h5 className="card-title">Nuestros Clientes</h5>
              <p className="card-text">Porque cada cliente merece lo mejor</p>
            </div>
            <Link to="/projects">
              <button className="btn-card-big">Ver más</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardIndex;
