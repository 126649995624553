import React from 'react'
import NavbarManagement from '../components/Navbar/Navbar'
import Banner from '../components/Banner/Banner'
import CardIndex from '../components/Cards/CardsIndex'
import BannerVideo from '../components/Banner/BannerVideo'
import SliderClients from "../components/Sliders/SliderClients"
import Footer from '../components/Footer/FooterInicio'
import ButtonCoach from '../components/Buttons/ButtonCoach'

const Home = () => {
  return (
    <div>
    <NavbarManagement/>
    <Banner/>
    <br/>
    <ButtonCoach/>
    <br/>
    <CardIndex/>
    <br/><br/><hr className='hr-white'/>
    <BannerVideo/>
    <hr className='hr-white-3'/>
    <SliderClients/>
    <br/><br/><hr className='hr-white-3'/>
    <Footer/>
    </div>
  )
}

export default Home