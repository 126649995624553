import React from 'react'
import Navbar from "../components/Navbar/Navbar"
import Projects from '../components/Projects/Projects'
import Footer from "../components/Footer/FooterInicio"

const OutClients = () => {
  return (
    <div>
        <Navbar/>
        <div>
            <br/>
        <Projects/>
        <Footer/>
        </div>
    </div>
  )
}

export default OutClients