import React from "react";
import "../../../styles/BannerService.css";
import Image from "../../../assets/img/BannerService3.png";
import { ArrowRightCircle } from "react-bootstrap-icons";

const BannerSistem = () => {
  return (
    <div id="banner-sistem" className="banner-landing mt-5">
      <hr className="hr-white-2" />
      <div className="h1-tittle">
        <h1>Desarrollo de aplicaciones o sistemas web</h1>
      </div>
      <div className="tagline-landing">
        <p>¿Para que sirve una aplicación o sistema web?</p>
      </div>
      <div className="p-text-image">
        <p className="p-text-banner-landing">
          Una aplicación web o sistema web autogestionable es mucho más que una
          simple página en línea. Es una herramienta versátil y poderosa
          diseñada para simplificar tu vida y potenciar tu negocio. Para los
          clientes, significa una experiencia interactiva y personalizada; para
          los propietarios, implica autonomía total sobre la gestión de
          contenidos y procesos. Desde la optimización de tareas hasta la
          entrega de experiencias únicas, nuestras soluciones web están creadas
          para transformar tu presencia digital y hacer que cada clic cuente.
          Descubre la diferencia de un sistema web que trabaja para ti.
        </p>
        <img src={Image} className="image-banner-service" alt="banner-sistem" />
      </div>
      <div className="tagline-landing tagline-center">
        <p>¿Por qué necesitas un software o aplicación web?</p>
      </div>
      <div className="card-container-landing">
        <div className="card card-landing col-12 mb-4 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Eficiencia Operativa</h5>
            <p className="card-text">
              Una aplicación o sistema web optimiza tus operaciones,
              simplificando tareas y mejorando la productividad en tu negocio.
            </p>
          </div>
        </div>
        <div className="card card-landing mb-4 col-12 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Experiencia del Cliente Mejorada</h5>
            <p className="card-text">
              Brinda a tus clientes una experiencia única y personalizada,
              aumentando la satisfacción y la lealtad a través de
              funcionalidades interactivas.
            </p>
          </div>
        </div>
        <div className="card card-landing mb-4 col-12 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Acceso a Datos Cruciales</h5>
            <p className="card-text">
              Obtén acceso rápido y fácil a datos importantes que te permitirán
              tomar decisiones informadas y mejorar continuamente tus
              estrategias.
            </p>
          </div>
        </div>
      </div>
      <div className="card-container-landing-2">
        <div className="card card-landing col-12 mb-4 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Autonomía y Control</h5>
            <p className="card-text">
              Toma el control total de tu presencia digital. Actualiza,
              personaliza y gestiona tu aplicación o sistema web sin depender de
              terceros.
            </p>
          </div>
        </div>
        <div className="card card-landing mb-4 col-12 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Adaptación a Cambios del Mercado</h5>
            <p className="card-text">
              Responde rápidamente a las fluctuaciones del mercado y a las
              necesidades cambiantes de tus clientes, manteniendo tu negocio
              relevante y competitivo.
            </p>
          </div>
        </div>
        <div className="card card-landing mb-4 col-12 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Escalabilidad para el Crecimiento</h5>
            <p className="card-text">
              Prepara tu negocio para el crecimiento futuro. Escala tu
              aplicación o sistema web según tus necesidades y objetivos
              empresariales, sin restricciones predefinidas.
            </p>
          </div>
        </div>
      </div>
      <a
        href="https://wa.me/3512035657?text=Quiero%20asesoramiento
"
      >
        <div className="container-button-banner-landing">
          <button className="button-connect-2-landing">
            Quiero hacer mi sistema o aplicación web{" "}
            <ArrowRightCircle size={25} className="icon-service" />
          </button>
        </div>
      </a>
      <hr className="hr-white" />
    </div>
  );
};

export default BannerSistem;
