import React from "react";
import "../../styles/Banner2.css";
import Image1 from "../../assets/img/Plans1.jpg";
import Image2 from "../../assets/img/Plans2.jpg";
import Image3 from "../../assets/img/Plans3.jpg";
import Image4 from "../../assets/img/Plans4.jpg";
import Image5 from "../../assets/img/Plans5.jpg";

const PlansBody = () => {
  return (
    <div id="banner-plans" className="container-banner-industry container">
      <div className="banner-industry">
        <hr className="hr-white-3" />
        <h2>Plan básico</h2>
        <div className="grid-industry">
          <div className="bannerp">
            <p>
              Landing Page: Desarrollo de una landing page profesional y
              atractiva. Integración de formulario de contacto.
            </p>
            <p>
              SEO Básico: Optimización de palabras clave. Configuración de
              metaetiquetas y descripciones.
            </p>
          </div>
          <img src={Image1} alt="banner" />
        </div>
      </div>
      <div className="banner-industry">
        <hr className="hr-white-3" />
        <h2>Plan estándar</h2>
        <div className="grid-industry">
          <div className="bannerp">
            <p>
              {" "}
              Landing Page avanzada, diseño personalizado y adaptado a la
              identidad de la marca. Integración de elementos multimedia y
              llamadas a la acción.
            </p>
            <p>
              SEO Avanzado: Análisis de competencia, estrategia de construcción
              de enlaces e informes de análisis de tráfico.
            </p>
          </div>
          <img src={Image2} alt="banner" />
        </div>
      </div>

      <div className="banner-industry">
        <hr className="hr-white-3" />
        <h2>Aplicación web simple</h2>
        <div className="grid-industry">
          <div className="bannerp">
            <p>
              Desarrollo de una aplicación web básica. Funcionalidades
              personalizadas según las necesidades del cliente.
            </p>
          </div>
          <img src={Image3} alt="banner" />
        </div>
      </div>

      <div className="banner-industry">
        <hr className="hr-white-3" />
        <h2>Plan Premium</h2>
        <div className="grid-industry">
          <div className="bannerp">
            <p>
              Landing Page Premium, diseño altamente personalizado y
              experiencial. Integración de herramientas de análisis de
              comportamiento de usuarios.
            </p>
            <p>
              SEO Premium, Investigación de palabras clave avanzada, estrategia
              de contenido y marketing de contenidos, monitoreo continuo y
              ajustes estratégicos.
            </p>
            <p>
              Sistema con Base de Datos Autogestionable: Desarrollo de un
              sistema personalizado con interfaz intuitiva y capacitación para
              la gestión de contenidos.
            </p>
          </div>
          <img src={Image4} alt="banner" />
        </div>
      </div>

      <div className="banner-industry">
        <hr className="hr-white-3" />
        <h2>Plan Empresarial Total</h2>
        <div className="grid-industry">
          <div className="bannerp">
            <p>
              Diseño de múltiples páginas. Integración de chat en vivo y
              formularios avanzados.
            </p>
            <p>
              SEO Integral: Estrategia multicanal, auditorías regulares y
              ajustes estratégicos. Desarrollo de estrategias de marketing
              digital.
            </p>
            <p>
              Aplicación Web Compleja: Desarrollo de una aplicación web compleja
              con características avanzadas, integración con sistemas existentes
              si es necesario, soporte continuo y actualizaciones.
            </p>
            <p>
              Sistema Complejo con Base de Datos Autogestionable: Desarrollo de
              un sistema robusto y escalable. Funcionalidades avanzadas de
              gestión de datos y usuarios. Soporte técnico y mantenimiento
              continuo.
            </p>
            <p>
              Posicionamiento de Marca: Estrategia integral de marca, desarrollo
              de contenido de marca y gestión de reputación online.
            </p>
          </div>
          <img src={Image5} alt="banner" />
        </div>
      </div>
    </div>
  );
};

export default PlansBody;
