import React from "react";
import NavbarManagement from "../components/Navbar/Navbar";
import BannerDistincion from "../components/Banner/BannerDistincion/BannerDistincion";
import ExperienceUser from "../components/Banner/BannerDistincion/ExperienceUser";
import ButtonAsist from "../components/Buttons/ButtonCoach"
import ResponsiveExp from "../components/Banner/BannerDistincion/ResponsiveExp";
import AutomationBanner from "../components/Banner/BannerDistincion/AutomationBanner";
import Footer from "../components/Footer/FooterInicio"
const Distinction = () => {
  return (
    <div>
      <NavbarManagement/>
      <br/>
      <BannerDistincion/>
      <ButtonAsist/>
      <ExperienceUser/>
      <ResponsiveExp/>
      <AutomationBanner/>
      <Footer/>
    </div>
  );
};

export default Distinction;
