import React from "react";
import { ArrowRightCircle } from "react-bootstrap-icons";

const BannerBodyIndustry = () => {
  return (
    <div id="banner-industry-right" className="banner-landing">
      <hr className="hr-white-2" />
      <div className="h1-tittle">
        <h1>Industrias en las que trabajamos</h1>
      </div>
      <div className="card-container-landing">
        <div className="card card-landing col-12 mb-4 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Tecnología y Software</h5>
            <p className="card-text">
              Desarrollo de sitios web corporativos y aplicaciones de software.
            </p>
          </div>
        </div>
        <div className="card card-landing mb-4 col-12 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Presencia en Línea para Empresas</h5>
            <p className="card-text">
              Desarrollo de sitios web corporativos para mejorar la visibilidad
              y credibilidad de la empresa. Creación de aplicaciones
              personalizadas para satisfacer necesidades específicas de la
              empresa.
            </p>
          </div>
        </div>
        <div className="card card-landing mb-4 col-12 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Sistemas de Gestión Personalizados</h5>
            <p className="card-text">
              Creación de sistemas web para gestionar procesos internos de
              pequeñas empresas y emprendimientos. Desarrollo de aplicaciones
              personalizadas para mejorar la productividad individual y
              empresarial.
            </p>
          </div>
        </div>
      </div>
      <div className="card-container-landing-2">
        <div className="card card-landing col-12 mb-4 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Portafolios y Presentación Personal</h5>
            <p className="card-text">
              Creación de sitios web personalizados para presentar portafolios y
              habilidades individuales. Desarrollo de aplicaciones para destacar
              logros personales y proyectos.
            </p>
          </div>
        </div>
        <div className="card card-landing mb-4 col-12 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Plataformas de Reservas y Citas</h5>
            <p className="card-text">
              Creación de sistemas de reserva en línea para servicios como
              consultas, citas médicas o reservas de restaurantes. Desarrollo de
              aplicaciones que permitan a los usuarios programar citas de manera
              eficiente.
            </p>
          </div>
        </div>
        <div className="card card-landing mb-4 col-12 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Comercio Electrónico</h5>
            <p className="card-text">
              Desarrollo de tiendas en línea y aplicaciones de comercio
              electrónico.
            </p>
          </div>
        </div>
      </div>
      <div className="card-container-landing-2">
        <div className="card card-landing col-12 mb-4 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Alimentación y Bebidas</h5>
            <p className="card-text">
              Diseño de sitios web para restaurantes y agendas de turnos
            </p>
          </div>
        </div>
        <div className="card card-landing mb-4 col-12 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Manufactura</h5>
            <p className="card-text">
              Crear sistemas de gestión de inventario en línea, sitios web
              corporativos y aplicaciones para la cadena de suministro.
            </p>
          </div>
        </div>
        <div className="card card-landing mb-4 col-12 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Automotriz</h5>
            <p className="card-text">
              Crear sistemas de gestión de inventario en línea. Creación de
              sistemas web para gestionar procesos internos. Creación de
              sistemas de reserva en línea
            </p>
          </div>
        </div>
      </div>
      <a href="https://wa.me/3512035657?text=Quiero%20asesoramiento
">
      <div className="container-button-banner-landing">
        <button className="button-connect-2-landing">
          Quiero asesoramiento{" "}
          <ArrowRightCircle size={25} className="icon-service" />
        </button>
      </div>
      </a>
      <hr className="hr-white" />
    </div>
  );
};

export default BannerBodyIndustry;
