import React from 'react'
import "../../../styles/BannerDistincion.css"
import Image from "../../../assets/img/BannerExperience3.png"

const ResponsiveExp  = () => {
  return (
    <div id="banner-experience" className='banner-experience container'>
        <hr className='hr-whiate-2'/>
        <div className='h1-tittle'>
            <h1>Diseño Responsivo</h1>
        </div>
        <div className='tagline-experience'>
            <p>Experiencia web perfecta</p>
        </div>
        <div className='p-text-image-experience'>
            <p className='p-text-banner-experience'> 
            El desarrollo responsive es la clave para una presencia en línea moderna y eficaz. Al adaptar nuestro enfoque a dispositivos de todos los tamaños, desde móviles hasta pantallas de escritorio, creamos experiencias consistentes y atractivas. La flexibilidad del desarrollo responsive no solo mejora la visibilidad en motores de búsqueda, sino que también asegura que tu audiencia disfrute de una navegación fluida y agradable en cualquier dispositivo.
            </p>
            <img src={Image} className='image-banner-experience' alt='banner'/>
        </div>
        <hr className="hr-white-3" />
    </div>
  )
}

export default ResponsiveExp;