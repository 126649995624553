import React from 'react'
import Navbar from "../components/Navbar/Navbar"
import TechnologiesTop from "../components/Technologies/TechnologiesTop"
import TechnologiesBody from '../components/Technologies/TechnologiesBody'
import Footer from "../components/Footer/FooterInicio"
const Technologies = () => {
  return (
    <div>
        <Navbar/>
        <div>
            <TechnologiesTop/>
            <TechnologiesBody/>
        </div>
        <Footer/>
    </div>
  )
}

export default Technologies