import React from "react";
import { Link } from "react-router-dom";
import "../../styles/Footer.css";
import navIcon1 from "../../assets/img/nav-icon1.svg";
import navIcon5 from "../../assets/img/nav-icon5.svg";
import Instagram from "../../assets/img/Instagram.png";

export const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer>
      <section className="footer-top-wrapper">
        <section className="footer-top">
          <hr className="hr-white-4" />
          <span className="social-icon">
            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7090171525525299200?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7090171525525299200%29">
              <img src={navIcon1} alt="Social Icon" />
            </a>
            <a href="https://wa.me/3512035657">
              <img src={navIcon5} alt="Social Icon" />
            </a>
            <a href="https://www.instagram.com/metacodeargentina/">
              <img src={Instagram} alt="Social Icon" />
            </a>
          </span>
        </section>

        <section>
          <hr className="hr-white-4" />
          <menu className="menu-footer">
            <h2>Metacode Learning</h2>
            <li>
              <a href="https://www.tiktok.com/@metacodearg?lang=es">TikTok</a>
            </li>
            <li>
              <a href="https://www.instagram.com/metacodeargentina/">
                Instagram
              </a>
            </li>
          </menu>
        </section>

        <section>
          <hr className="hr-white-4" />

          <menu className="menu-footer">
            <h2>Skills</h2>
            <li>
              <Link to="/services">Servicios</Link>
            </li>
            <li>
              <Link to="/innovation">Innovación</Link>
            </li>
            <li>
              <Link to="/industries-served">Industrias Atendidas</Link>
            </li>
          </menu>
        </section>
        <section>
          <hr className="hr-white-4" />
          <menu className="menu-footer">
            <h2>Proyectos</h2>
            <li>
              <Link to="/projects">Nuestros clientes</Link>
            </li>
            <li>
              <Link to="/app-barbershop">Aplicaciones web</Link>
            </li>
          </menu>
        </section>

        <section>
          <hr className="hr-white-4" />
          <menu className="menu-footer">
            <h2>Productos</h2>
            <li>
              <Link to="/plans">Planes</Link>
            </li>
            <li>
              <Link to="#connect">Barber Map</Link>
            </li>
            <li>
              <Link to="#connect">CapturePro Events</Link>
            </li>
          </menu>
        </section>
        <section>
          <hr className="hr-white-4" />
          <menu className="menu-footer">
            <h2>Empresa</h2>
            <li>
              <Link to="/distinciónexperiencia">Distinción</Link>
            </li>
            <li>
              <Link
                to="https://wa.me/3512035657?text=Quiero%20asesoramiento
"
              >
                Contacto
              </Link>
            </li>
            <li>
              <Link to="#connect">Noticias</Link>
            </li>
          </menu>
        </section>
      </section>
      <section className="footer-bottom">
        <small>
          Todo el contenido &copy;{year}, Metacode.Todos los derechos reservados
        </small>
      </section>
    </footer>
  );
};

export default Footer;
