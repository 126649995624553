import React from "react";
import NavbarManagement from "../components/Navbar/Navbar";
import BannerInnovation from "../components/Banner/BannerInnovation/BannerInnovation";
import BannerGrid from "../components/Banner/BannerInnovation/BannerGrid";
import BannerInnoBody from "../components/Banner/BannerInnovation/BannerInnoBody";
import Footer from "../components/Footer/FooterInicio"
const Innovation = () => {
  return (
    <div>
      <NavbarManagement />
      <div>
        <BannerInnovation />
        <BannerGrid />
        <BannerInnoBody/> 
      </div>
      <Footer/> 
    </div>
  );
};

export default Innovation;
