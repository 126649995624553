import React, { useState } from 'react';
import "../../../styles/BannerDistincion.css";
import "../../../styles/BannerInnovation.css"

import Video from "../../../assets/videos/Metacode.mp4"
const BannerInnovation = () => {
  const [showVideo, setShowVideo] = useState(false);

  const handleVideoClick = () => {
    setShowVideo(true);
  };

  const handleCloseClick = () => {
    setShowVideo(false);
  };

  return (
    <div>
      <div className="banner-container-distincion">
        <div className="text-distincion">
          <hr className="hr-white-3" />
          <h1 className="h1-banner-tittle">
            <span>El poder de Metacode</span>
          </h1>
        </div>
        {!showVideo ? (
          <button
            id="btn-distincion"
            className="btn-card btn-distincion"
            onClick={handleVideoClick}
          >
            Ver video
          </button>
        ) : (
            
          <div className='container-video-innovation'>
             <button className="close-button" onClick={handleCloseClick}>
             <i class="fa-solid fa-xmark"></i>
            </button>
            <video controls className='video-controls'>
              <source src={Video}/>
              Tu navegador no soporta el tag de video.
            </video>
          </div>
        )}
      </div>
    </div>
  );
};

export default BannerInnovation;
