import React from "react";
import Navbar from "../components/Navbar/Navbar";
import ButtonCoach from "../components/Buttons/ButtonCoach";
import BannerService from "../components/Banner/BannerService/BannerService";
import BannerLanding from "../components/Banner/BannerService/BannerLanding";
import BannerSistem from "../components/Banner/BannerService/BannerSistem";
import BannerMarketing from "../components/Banner/BannerService/BannerMarketing";
import Footer from "../components/Footer/FooterInicio"
import AnimatedRight from "../components/common/AnimatedRight";

const Services = () => {
  return (
    <div>
      <Navbar />
      <BannerService />
      <ButtonCoach />
      <AnimatedRight>
        <BannerLanding />
      </AnimatedRight>
      <AnimatedRight>
        <BannerSistem />
      </AnimatedRight>
      <AnimatedRight>
        <BannerMarketing />
      </AnimatedRight>
      <Footer />
    </div>
  );
};

export default Services;
