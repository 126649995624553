import React from "react";
import "../../styles/Text.css";
import "../../styles/BannerService.css";
import Image from "../../assets/img/Photography2.png";
import { ArrowRightCircle } from "react-bootstrap-icons";
// import { Link } from "react-router-dom";

const PhotoBody = () => {
  return (
    <div id="banner-photography" className="banner-landing mt-5"> 
      <hr className="hr-white-2" />
      <div className="h1-tittle">
        <h1>En metacode desarrollamos una aplicación web para fotógrafos</h1>
      </div>
      <div className="tagline-landing">
        <p>¿En que ayuda esta aplicación web?</p>
      </div>
      <div className="p-text-image">
        <p className="p-text-banner-landing">
          Un software diseñado específicamente para fotógrafos es una herramienta integral que va más allá de la simple gestión de imágenes, mejorando la eficiencia operativa, proporcionando análisis valiosos y elevando la experiencia tanto para el fotógrafo como para sus clientes.
          En la página web vas a poder gestionar eventos , cargar fotos, ver estadísticas, interactuar con los usuarios, y un monton de funciones más!
        </p>
        <img src={Image} className="image-banner-service" alt="banner"/>
      </div>
      <div className="container-button-banner-landing">
        <button className="button-connect-2-landing">
          Quiero más información <ArrowRightCircle size={25} className="icon-service"/>
        </button>
      </div>
      <hr className="hr-white" />
      
    </div>
  );
};

export default PhotoBody;
