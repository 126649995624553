import React from "react";
import "../../../styles/BannerService.css";
import Image from "../../../assets/img/BannerService3.png";
import { ArrowRightCircle } from "react-bootstrap-icons";

const BannerMarketing = () => {
  return (
    <div id="banner-marketing" className="banner-landing mt-5">
      <hr className="hr-white-2" />
      <div className="h1-tittle">
        <h1>Marketing Digital y posicionamiento SEO</h1>
      </div>
      <div className="tagline-landing">
        <p>¿Qué es el marketing digital y SEO?</p>
      </div>
      <div className="p-text-image">
        <p className="p-text-banner-landing">
          El marketing digital, potenciado por el SEO, es el motor que impulsa
          tu marca hacia el éxito en el vasto universo en línea. Más que una
          simple promoción, es una estrategia integral que eleva tu visibilidad,
          atrae a tu audiencia ideal y construye conexiones duraderas. Desde
          mejorar el posicionamiento en buscadores hasta optimizar conversiones,
          el marketing digital con SEO te empodera para alcanzar nuevas alturas
          y destacar en un mercado saturado. Descubre el poder de esta
          estrategia digital combinada con SEO y transforma tu marca en una
          fuerza relevante y resonante en el mundo digital actual.
        </p>
        <img src={Image} className="image-banner-service" alt="banner"/>
      </div>
      <div className="tagline-landing tagline-center">
        <p>
          ¿Cuáles son los beneficios del marketing digital y posicionamiento
          SEO?
        </p>
      </div>
      <div className="card-container-landing">
        <div className="card card-landing col-12 mb-4 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Visibilidad Mejorada</h5>
            <p className="card-text">
            Eleva tu presencia en línea con estrategias de marketing digital y SEO, asegurando que tu marca sea fácilmente encontrada por tu audiencia.
            </p>
          </div>
        </div>
        <div className="card card-landing mb-4 col-12 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Atracción de Audiencia Relevante</h5>
            <p className="card-text">
            Utiliza técnicas de SEO para dirigirte a tu público objetivo, atrayendo visitantes más propensos a convertirse en clientes reales.
            </p>
          </div>
        </div>
        <div className="card card-landing mb-4 col-12 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Optimización de Conversiones</h5>
            <p className="card-text">
            A través del análisis de datos y estrategias de marketing digital, mejora las tasas de conversión para maximizar el impacto de tus esfuerzos en línea.
            </p>
          </div>
        </div>
      </div>
      <div className="card-container-landing-2">
        <div className="card card-landing col-12 mb-4 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Credibilidad y Confianza</h5>
            <p className="card-text">
            Un sólido posicionamiento en buscadores y presencia digital construye la credibilidad de tu marca, generando confianza entre tus clientes potenciales.
            </p>
          </div>
        </div>
        <div className="card card-landing mb-4 col-12 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Medición y Análisis Preciso</h5>
            <p className="card-text">
            Aprovecha las herramientas analíticas para medir el rendimiento, entender el comportamiento del usuario y ajustar estrategias de manera efectiva.
            </p>
          </div>
        </div>
        <div className="card card-landing mb-4 col-12 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Rentabilidad a Largo Plazo</h5>
            <p className="card-text">
            Las estrategias de SEO y marketing digital brindan resultados a largo plazo, ofreciendo un retorno de inversión continuo a medida que tu presencia en línea se fortalece.
            </p>
          </div>
        </div>
      </div>
      <a href="https://wa.me/3512035657?text=Quiero%20asesoramiento
">
      <div className="container-button-banner-landing">
        <button className="button-connect-2-landing">
          Quiero un plan de Marketing{" "}
          <ArrowRightCircle size={25} className="icon-service" />
        </button>
      </div>
      </a>
      <hr className="hr-white" />
    </div>
  );
};

export default BannerMarketing;
