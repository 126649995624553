import React from 'react'
import NavbarManagement from '../components/Navbar/Navbar'
import PlansHeader from '../components/Plans/PlansHeader'
import PlansBody from '../components/Plans/PlansBody'
import Footer from '../components/Footer/FooterInicio'
import ButtonCoach from '../components/Buttons/ButtonCoach'

const Plans = () => {
  return (
    <div>
        <NavbarManagement/>
        <div>
            <PlansHeader/>
            <ButtonCoach/>
            <PlansBody/>
            <Footer/>
        </div>
    </div>
  )
}

export default Plans