import React from "react";
import BannerHeaderIndustry from "../components/Banner/BannerIndustry/BannerHeaderIndustry";
import NavbarManagement from "../components/Navbar/Navbar";
import BannerGridIndustry from "../components/Banner/BannerIndustry/BannerGridIndustry";
import BannerBodyIndustry from "../components/Banner/BannerIndustry/BannerBodyIndustry";
import BannerBodyBot from "../components/Banner/BannerIndustry/BannerBodyBot"
import Footer from "../components/Footer/FooterInicio"

const Industries = () => {
    return (
        <div>
            <NavbarManagement />
            <div>
                <BannerHeaderIndustry />
                <BannerGridIndustry/>
                <BannerBodyIndustry/>
                <BannerBodyBot/>
                <Footer/>
            </div>
        </div>
    )
}

export default Industries;