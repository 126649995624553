import React from 'react'
import "../../../styles/BannerDistincion.css"
import Image from "../../../assets/img/BannerExperience2.png"

const ExperienceUser = () => {
  return (
    <div id="banner-experience" className='banner-experience container'>
        <hr className='hr-white-2'/>
        <div className='h1-tittle'>
            <h1>Redefinimos el concepto de experiencia de usuario y diseño</h1>
        </div>
        <div className='tagline-experience'>
            <p>Experiencia de usuario</p>
        </div>
        <div className='p-text-image-experience'>
            <p className='p-text-banner-experience'> 
            En el corazón de cada proyecto, priorizamos la Experiencia de Usuario UX y la Interfaz de Usuario UI para crear soluciones digitales. Nuestra dedicación a la UX garantiza que cada interacción sea intuitiva y valiosa para el usuario, mientras que nuestra atención meticulosa a la UI garantiza un diseño visualmente impactante y coherente.
            </p>
            <img src={Image} className='image-banner-experience' alt='banner'/>
        </div>
        <hr className="hr-white-3" />
    </div>
  )
}

export default ExperienceUser