import React, { forwardRef } from "react";
import "../../../styles/Text.css";
import "../../../styles/BannerService.css";
import Image from "../../../assets/img/BannerService.png";
import { ArrowRightCircle } from "react-bootstrap-icons";
// import { Link } from "react-router-dom";

const BannerLanding = forwardRef((props, ref) => {
  return (
    <div id="banner-landing" className="banner-landing mt-5" ref={ref}>
      <hr className="hr-white-2" />
      <div className="h1-tittle">
        <h1>Desarrollo de Landing page</h1>
      </div>
      <div className="tagline-landing">
        <p>¿Qué es una landing page?</p>
      </div>
      <div className="p-text-image">
        <p className="p-text-banner-landing">
          Una landing page es tu carta de presentación digital, diseñada para
          cautivar a tus visitantes y convertirlos en clientes. Es una página
          web única, enfocada en una oferta o mensaje específico, con el
          objetivo de impulsar acciones, capturar leads o generar conversiones.
          Simple, efectiva y diseñada para destacar, una landing page maximiza
          el impacto de tu mensaje y acelera los resultados en línea
        </p>
        <img src={Image} className="image-banner-service" alt="banner" />
      </div>
      <div className="tagline-landing tagline-center">
        <p>¿Cuáles son los beneficios de tener una landing page?</p>
      </div>
      <div className="card-container-landing">
        <div className="card card-landing col-12 mb-4 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Impacto Inmediato</h5>
            <p className="card-text">
              Destaca tu mensaje clave de manera directa y atractiva, capturando
              la atención desde el primer vistazo
            </p>
          </div>
        </div>
        <div className="card card-landing mb-4 col-12 col-md-4">
          <div className="card-body">
            <h5 className="card-title"> Conversión Optimizada</h5>
            <p className="card-text">
              Diseñada para motivar a los visitantes a tomar acciones
              específicas, convirtiéndolos en clientes potenciales o clientes
              reales.
            </p>
          </div>
        </div>
        <div className="card card-landing mb-4 col-12 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Fácil Autogestión</h5>
            <p className="card-text">
              Simplifica la administración de contenidos y datos, permitiéndote
              actualizar tu landing page de manera intuitiva y sin
              complicaciones.
            </p>
          </div>
        </div>
      </div>
      <div className="card-container-landing-2">
        <div className="card card-landing col-12 mb-4 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Segmentación Efectiva</h5>
            <p className="card-text">
              Dirige mensajes personalizados a segmentos específicos de tu
              audiencia, maximizando la relevancia y aumentando las
              conversiones.
            </p>
          </div>
        </div>
        <div className="card card-landing mb-4 col-12 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Datos Accesibles</h5>
            <p className="card-text">
              Obtén información valiosa sobre el comportamiento de tus
              visitantes, facilitando la toma de decisiones informadas para
              optimizar tu estrategia.
            </p>
          </div>
        </div>
        <div className="card card-landing mb-4 col-12 col-md-4">
          <div className="card-body">
            <h5 className="card-title">Rápida Implementación</h5>
            <p className="card-text">
              Despliega tu landing page rápidamente, acelerando el tiempo desde
              la conceptualización hasta los resultados tangibles.
            </p>
          </div>
        </div>
      </div>
      <a
        href="https://wa.me/3512035657?text=Quiero%20asesoramiento
"
      >
        <div className="container-button-banner-landing">
          <button className="button-connect-2-landing">
            Quiero hacer mi landing page{" "}
            <ArrowRightCircle size={25} className="icon-service" />
          </button>
        </div>
      </a>
      <hr className="hr-white" />
    </div>
  );
});

export default BannerLanding;
