import React from "react";
import Image from "../../../assets/img/BannerIndustry.jpg"
import "../../../styles/Banner2.css"
import Image2 from "../../../assets/img/BannerIndustry2.webp"
import Image3 from "../../../assets/img/BannerIndustry3.jpg"
import Image4 from "../../../assets/img/BannerIndustry4.jpg"

const BannerBodyIndustry = () => {
    return (
        <div id="banner-industry-left" className="container-banner-industry container">
            <div className="banner-industry">
                <hr className='hr-white-3' />
                <h2>PYMES</h2>
                <div className="grid-industry">
                    <div className="bannerp">
                        <p> Creación de un sitio web corporativo para mejorar la presencia en línea y proporcionar información sobre productos y servicios.</p>
                        <p>Desarrollo de sistemas de gestión interna para optimizar procesos y mejorar la eficiencia operativa.</p>
                        <p>Implementación de plataformas de comercio electrónico para ampliar el alcance y facilitar las ventas en línea.</p>
                    </div>
                    <img src={Image} alt="banner" />
                </div>
            </div>
            <div className="banner-industry">
                <hr className='hr-white-3' />
                <h2>EMPRENDIMIENTOS</h2>
                <div className="grid-industry">
                    <div className="bannerp">
                        <p> Desarrollo de una página web o aplicación para presentar la idea del emprendimiento y atraer inversores o clientes potenciales.</p>
                        <p>Creación de una plataforma en línea para la comercialización de productos o servicios específicos del emprendimiento.</p>
                    </div>
                    <img src={Image2} alt="banner" />
                </div>
            </div>

            <div className="banner-industry">
                <hr className='hr-white-3' />
                <h2>EMPRESAS</h2>
                <div className="grid-industry">
                    <div className="bannerp">
                        <p>Desarrollo de sitios web corporativos para mejorar la visibilidad y credibilidad de la empresa.</p>
                        <p>Creación de aplicaciones personalizadas para satisfacer necesidades específicas de la empresa.</p>
                    </div>
                    <img src={Image4} alt="banner" />
                </div>
            </div>

            <div className="banner-industry">
                <hr className='hr-white-3' />
                <h2>PARTICULARES EN GENERAL</h2>
                <div className="grid-industry">
                    <div className="bannerp">
                        <p>Diseño y desarrollo de una página web personal para destacar habilidades, logros o proyectos personales.</p>
                        <p>Creación de blogs o sitios web para compartir conocimientos, experiencias o pasatiempos.</p>
                        <p>Desarrollo de aplicaciones móviles para mejorar la productividad personal o facilitar tareas específicas.</p>
                    </div>
                    <img src={Image3} alt="banner" />
                </div>
            </div>

        </div>
    )
}


export default BannerBodyIndustry;