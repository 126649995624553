import { useState, useEffect } from "react";
import { ArrowRightCircle } from "react-bootstrap-icons";
import "../../styles/Banner.css";
import Image1 from "../../assets/img/Banner2.jpg";
import Image2 from "../../assets/img/Banner1.jpg";
import Image3 from "../../assets/img/Banner3.jpg";
import { Link } from "react-router-dom";

const imageList = [Image1, Image2, Image3];
const textList = [
  "Con un enfoque integral en sistemas, una profunda experiencia en productos y un conocimiento completo de la tecnología web, nuestra empresa vislumbra el panorama completo del desarrollo digital. Ofrecemos innovaciones que impulsan el futuro de la conectividad en línea, diseñando soluciones que van más allá para potenciar la presencia digital de nuestros clientes en el paisaje tecnológico actual.",
  "En Metacode, superamos las expectativas del desarrollo de software al diseñar experiencias excepcionales centradas en el usuario. Vamos más allá del código para fusionar funcionalidad y estética, liderando la creación de soluciones digitales.",
  "Descubre la revolución en la gestión de barberías con BarberMap. Ahorra tiempo agendando turnos de manera eficiente. Nuestra plataforma permite a los dueños y barberos cargar turnos en línea fácilmente. Además, ofrecemos a tus clientes la libertad de autogestionar sus servicios y horarios. Simplifica tu negocio y brinda una experiencia de reserva sin complicaciones con nuestro software para peluquerías",
];
const textList2 = [
  "Innovación",
  "Desarrollo web",
  "Últimas noticias",
];
const textList3 = [
  "El Poder de Metacode",
  "Desarrollamos tu pagina web",
  "Metacode Lanza el Nuevo Software para Barberías",
];

const rutes = [
  <h6 className="h6-banner">
    <Link to="/1">Conoce más acerca de Metacode</Link>
  </h6>,
  <h6 className="h6-banner">
    <Link to="/services">Conoce nuestros servicios</Link>
  </h6>,
  <h6 className="h6-banner">
    <Link to="/3">Conoce más acerca de BarberMap</Link>
  </h6>,
];
const transitionDuration = 10000;

export const Banner = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1); // Index of the next image

  useEffect(() => {
    const interval = setInterval(() => {
      setNextImageIndex((currentImageIndex + 1) % imageList.length);
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
    }, transitionDuration);

    return () => {
      clearInterval(interval);
    };
  }, [currentImageIndex]);

  return (
    
    <section className="banner" id="home">
        <div className="container-banner">   
          <hr className="hr-white-2" />    
          <span className="tagline">{textList2[currentImageIndex]}</span>

          <h1>
            <span>{textList3[currentImageIndex]}</span>
          </h1>
          <p className="p-text-banner">{textList[currentImageIndex]}</p>
          <div className="container-button-banner">
            <button className="button-connect-2">
              {rutes[currentImageIndex]} <ArrowRightCircle size={25} />
            </button>
          </div>
        </div>
      
      <div className="image-transition-container">
        
        <div className="background-gradient"></div>
        <div className="image-wrapper">
          {imageList.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Metacode ${index}`}
              className="transition-image"
              style={{
                opacity:
                  index === currentImageIndex || index === nextImageIndex
                    ? 1
                    : 0,
              }}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Banner;
