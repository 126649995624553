import React from "react";
import "../../styles/Banner.css";
import Video from "../../assets/videos/Video.mp4";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div className="banner-container">
      <video autoPlay muted loop className="video-background">
        <source src={Video} type="video/mp4" />
        Tu navegador no soporta el elemento de video.
      </video>
      <div className="content">
        <div className="inner-content">
          <h1 className="h1-banner-video">
            Descubre la libertad de la automatización en tu negocio.
          </h1>
          <p className="p-banner-video">
            Revoluciona tu forma de gestionar tu negocio con nuestras soluciones
            de automatización, liberando tu potencial empresarial. Descubre cómo
            nuestras plataformas autogestionables simplifican tus procesos,
            permitiéndote enfocarte en el crecimiento y el logro de metas.
          </p>
          <Link to="/plans">
          <button className="btn-card-video">
            Conoce tu plan ideal{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-right-short"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
              />
            </svg>
          </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Banner;
