import React, { forwardRef, useEffect, useCallback } from "react";
import { motion, useAnimation } from "framer-motion";
import "bootstrap/dist/css/bootstrap.min.css";

const AnimatedRight = forwardRef(({ children }, ref) => {
  const controls = useAnimation();

  // Función para calcular la posición del componente
  const calculatePosition = useCallback(() => {
    const element = ref?.current;
    if (!element) return 0;
    const elementTop = element.getBoundingClientRect().top;
    return elementTop;
  }, [ref]);

  // Función para animar el banner
  const animateBanner = useCallback(async () => {
    await controls.start({
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        stiffness: 1000,
        damping: 100,
      },
    });
  }, [controls]);

  // Función para manejar el scroll y activar la animación cuando el componente está a punto de entrar en la vista
  const handleScroll = useCallback(() => {
    const position = calculatePosition();
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;

    if (scrollY + windowHeight * 0.8 > position) {
      animateBanner();
      window.removeEventListener("scroll", handleScroll);
    }
  }, [calculatePosition, animateBanner]);

  useEffect(() => {
    const position = calculatePosition();
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;

    if (scrollY + windowHeight * 0.8 > position) {
      animateBanner();
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [calculatePosition, animateBanner, handleScroll]);

  return (
    <motion.div ref={ref} initial={{ opacity: 0, x: -200 }} animate={controls}>
      {children}
    </motion.div>
  );
});

export default AnimatedRight;
