import React, { useEffect } from "react";
import "../../../styles/BannerDistincion.css";
import "../../../styles/Buttons.css";

const BannerHeaderIndustry = () => {
    useEffect(() => {
      const handleButtonClick = (targetId) => {
        const bannerExperienceElement = document.getElementById(targetId);
        if (bannerExperienceElement) {
          bannerExperienceElement.scrollIntoView({ behavior: "smooth" });
        }
      };
  
      const btnLeft = document.getElementById("btn-industry-left");
      const btnRight = document.getElementById("btn-industry-right");
  
      if (btnLeft) {
        btnLeft.addEventListener("click", () => handleButtonClick("banner-industry-left"));
      }
  
      if (btnRight) {
        btnRight.addEventListener("click", () => handleButtonClick("banner-industry-right"));
      }
  
      return () => {
        if (btnLeft) {
          btnLeft.removeEventListener("click", () => handleButtonClick("banner-industry-left"));
        }
  
        if (btnRight) {
          btnRight.removeEventListener("click", () => handleButtonClick("banner-industry-right"));
        }
      };
    }, []);
  
    return (
      <div>
        <div className="banner-container-distincion">
          <div className="text-distincion">
            <hr className="hr-white-3" />
            <h1 className="h1-banner-tittle">
              <span>Industrias Atendidas</span>
            </h1>
          </div>
          <div className="container-buttons-header">
            <button className="btn-card btn-banner-top " id="btn-industry-left">
              Tipo de clientes
            </button>
            <button className="btn-card btn-banner-top" id="btn-industry-right">
              Industrias atendidas
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default BannerHeaderIndustry;
