import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, link }) => {
  return (
    <Col size={12} sm={6} md={4}>
      {link ? (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <div className="proj-imgbx">
            <img src={imgUrl} alt=""/>
            <div className="proj-txtx">
              <h4>{title}</h4>
              <span>{description}</span>
            </div>
          </div>
        </a>
      ) : (
        <div className="proj-imgbx">
          <img src={imgUrl} alt=""/>
          <div className="proj-txtx">
            <h4>{title}</h4>
            <span>{description}</span>
          </div>
        </div>
      )}
    </Col>
  )
}