import React from "react";
import "../../styles/Banner2.css"


const PhotoGrid = () => {
    return (
        <div className="grid-industry-container">
            <div className="industry-grid">
            <hr className="hr-white-3" />
                <h4>Descubre la diferencia que puede hacer un software diseñado específicamente para fotógrafos</h4>
                <h3 className="d-flex justify-content-center color-white">Eleva tu fotografía con nuestro software personalizado</h3>
            </div>
        </div>
    )
}

export default PhotoGrid;