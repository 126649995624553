import React from "react";
import "../../../styles/Banner2.css"


const BannerGridIndustry = () => {
    return (
        <div className="grid-industry-container">
            <div className="industry-grid">
            <hr className="hr-white-3" />
                <h4>La versatilidad de nuestro desarrollo web y de aplicaciones se refleja en su aplicabilidad a diversos rubros empresariales.</h4>
                <h3 className="d-flex justify-content-center color-white">Realizamos desarrollo para todo tipo de clientes</h3>
            </div>
        </div>
    )
}

export default BannerGridIndustry;