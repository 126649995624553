import React from "react";
import "../../../styles/BannerDistincion.css";
import Image from "../../../assets/img/BannerExperience4.png";

const AutomationBanner = () => {
  return (
    <div id="banner-experience" className="banner-experience container">
      <hr className="hr-whiate-2" />
      <div className="h1-tittle">
        <h1>Automatización de Procesos</h1>
      </div>
      <div className="tagline-experience">
        <p>Optimiza tu negocio</p>
      </div>
      <div className="p-text-image-experience">
        <p className="p-text-banner-experience">
          La automatización de procesos a través de soluciones web, como el
          desarrollo de aplicaciones personalizadas, redefine la eficiencia
          operativa. Al integrar funcionalidades automatizadas, transformamos
          tareas manuales en procesos eficientes y sin fisuras. Desde la gestión
          de datos hasta la optimización de flujos de trabajo, nuestras
          soluciones web no solo simplifican, sino que potencian la
          productividad. Con el desarrollo de aplicaciones a medida, la
          automatización se convierte en una herramienta estratégica para
          liberar tiempo, minimizar errores y llevar la eficiencia de tu negocio
          a un nivel superior.
        </p>
        <img src={Image} className="image-banner-experience" alt="banner" />
      </div>
      <hr className="hr-white-3" />
    </div>
  );
};

export default AutomationBanner;
