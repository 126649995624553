import React, { useEffect } from "react";
import "../../styles/BannerDistincion.css";

const BannerBarber = () => {
  useEffect(() => {
    const handleButtonClick = () => {
      const bannerExperienceElement = document.getElementById("banner-barber");
      if (bannerExperienceElement) {
        bannerExperienceElement.scrollIntoView({ behavior: "smooth" });
      }
    };
    const btn = document.getElementById("btn-barber");
    if (btn) {
      btn.addEventListener("click", handleButtonClick);
    }
    return () => {
      if (btn) {
        btn.removeEventListener("click", handleButtonClick);
      }
    };
  }, []);
  return (
    <div>
      <div className="banner-container-distincion">
        <div className="text-distincion">
          <hr className="hr-white-3" />
          <h1 className="h1-banner-tittle">
            <span>Software para barberías</span>
          </h1>
        </div>
        <button id="btn-barber" className="btn-card btn-distincion">
          <i className="fa-solid fa-arrow-down"></i>
        </button>
      </div>
    </div>
  );
};

export default BannerBarber;
