import React from "react";
import "../../../styles/BannerInnovation.css";

const BannerGrid = () => {
  return (
    <div className="container-banner-grid">
      <div className="banner-grid">
        <hr className="hr-white-4" />
        <h5>¡Eleva tu negocio al siguiente nivel con Metacode!</h5>
        <i class="fa-solid fa-arrow-down-long icon-grid"></i>
      </div>
    </div>
  );
};

export default BannerGrid;
