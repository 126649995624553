import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../../assets/img/logo-simcor.png";
import projImg2 from "../../assets/img/naiken.png";
import "../../styles/ProjectStyles.css";
import { Fade } from "react-reveal";
import "animate.css";
import { ArrowRightCircle } from "react-bootstrap-icons";

export const Projects = () => {
  const projects = [
    {
      title: "SIMCOR",
      description: "Empresa del rubro eléctrico",
      imgUrl: projImg1,
      link: "https://simcor.netlify.app/",
    },
    {
      title: "NAIKEN",
      description: "Fotografía deportiva",
      imgUrl: projImg2,
      link: "https://naikenfotografia.com/",
    },
    /*
    {
      title: "PROXIMAMENTE",
      description: "En progreso",
      imgUrl: projImg2
    },
    {
      title: "PROXIMAMENTE",
      description: "En progreso",
      imgUrl: projImg2
    },
    {
      title: "PROXIMAMENTE",
      description: "En progreso",
      imgUrl: projImg2
    },
    {
      title: "PROXIMAMENTE",
      description: "En progreso",
      imgUrl: projImg2
    }
  ];

  const projects2 = [
    {
      title: "PROXIMAMENTE",
      description: "En progreso",
      imgUrl: projImg2
    },
    {
      title: "PROXIMAMENTE",
      description: "En progreso",
      imgUrl: projImg2
    },
    {
      title: "PROXIMAMENTE",
      description: "En progreso",
      imgUrl: projImg2
    },
    {
      title: "PROXIMAMENTE",
      description: "En progreso",
      imgUrl: projImg2
    },
    {
      title: "PROXIMAMENTE",
      description: "En progreso",
      imgUrl: projImg2
    },
    {
      title: "PROXIMAMENTE",
      description: "En progreso",
      imgUrl: projImg2
    }
  */
  ];
  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <Fade left>
              <div className={"animate__animated animate__fadeIn"}>
                <h2>Nuestros clientes</h2>
                <p>
                  Explora nuestra cartera diversa y descubre cómo hemos ayudado
                  a nuestros clientes a alcanzar sus objetivos en el mundo
                  digital. Únete a nosotros en este viaje emocionante mientras
                  creamos soluciones innovadoras para impulsar tu éxito
                </p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav
                    variant="pills"
                    className="nav-pills mb-5 justify-content-center align-items-center"
                    id="pills-tab"
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="first">Clientes</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Clientes</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Clientes</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content
                    id="slideInUp"
                    className={"animate__animated animate__slideInUp"}
                  >
                    <Tab.Pane eventKey="first">
                      <p>Clientes que confían en nuestros servicios</p>
                      <Row>
                        {projects.map((project, index) => {
                          return <ProjectCard key={index} {...project} />;
                        })}
                      </Row>
                    </Tab.Pane>
                    {/*
                      <Tab.Pane eventKey="second">
                        <p>Clientes que confían en nuestros servicios</p>
                        <Row>
                          {projects2.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p>Clientes que confían en nuestros servicios</p>
                        <Row>
                          {projects2.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                        */}
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Fade>
          </Col>
        </Row>
        <a
          href="https://wa.me/3512035657?text=Quiero%20asesoramiento
"
        >
          <div className="container-button-banner-landing">
            <button className="button-connect-2-landing">
              ¿Listo para tu próximo proyecto? ¡Hablemos!{" "}
              <ArrowRightCircle size={25} className="icon-service" />
            </button>
          </div>
        </a>
        <hr className="hr-white" />
      </Container>
    </section>
  );
};

export default Projects;
