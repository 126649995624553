import React from "react";
import NavbarManagement from "../components/Navbar/Navbar";
import BarberGrid from "../components/BarberApp/BarberGrid";
import BarberBody from "../components/BarberApp/BarberBody";
import BarberVideo from "../components/BarberApp/BarberVideo";
import BannerBarber from "../components/BarberApp/BannerBarber";
import Footer from "../components/Footer/FooterInicio";

const BarberApp = () => {
  return (
    <div>
      <NavbarManagement />
      <BannerBarber />
      <BarberGrid />
      <BarberBody />
      <div>
        <BarberVideo/>
        <Footer/>
      </div>
    </div>
  );
};

export default BarberApp;
