import './App.css'
import { Route, Routes, HashRouter as BrowserRouter } from "react-router-dom";
// Rutas generales
import Home from './pages/Home';
import Services from "./pages/Services"
import Distinction from './pages/Distinction';
import Industries from './pages/Industries';
import Innovation from "./pages/Innovation";
import Technologies from './pages/Technologies';
import OutClients from './pages/OutClients';
import PhotoApp from './pages/PhotoApp';
import BarberApp from './pages/BarberApp';
import Plans from './pages/Plans';
import BarberMap from './pages/BarberMap';

const App = () => {

  return (
    <>
      <div className='App'>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/services' element={<Services/>}/>
            <Route path='/distinciónexperiencia' element={<Distinction/>}/>
            <Route path='/innovation' element={<Innovation/>}/>
            <Route path='/industries-served' element={<Industries/>}/>
            <Route path='/technologies' element={<Technologies/>}/>
            <Route path='/projects' element={<OutClients/>}/>
            <Route path='/app-photography' element={<PhotoApp/>}/>
            <Route path='/app-barbershop' element={<BarberApp/>}/>
            <Route path='/plans' element={<Plans/>}/>
          </Routes>
        </BrowserRouter>
      </div>
    </>
  )
}

export default App