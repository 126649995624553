import React from 'react'
import Navbar from "../components/Navbar/Navbar"
import PhotoGrid from '../components/PhotographyApp/PhotoGrid'
import PhotographyVideo from "../components/PhotographyApp/PhotographyVideo"
import BannerPhoto from "../components/PhotographyApp/BannerPhoto"
import PhotoBody from '../components/PhotographyApp/PhotoBody'
import Footer from "../components/Footer/FooterInicio"
const PhotoApp = () => {
  return (
    <div>
        <Navbar/>
        <BannerPhoto/>
        <PhotoGrid/>
        <PhotoBody/>
        <div>
            <PhotographyVideo/>
            <Footer/>
        </div>
    </div>
  )
}

export default PhotoApp