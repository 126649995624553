import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../styles/Navbar.css";
import "@fortawesome/fontawesome-free/css/all.css";
import LogoMetacode from "../../assets/img/LogoMetacode.png";
import navIcon1 from "../../assets/img/nav-icon1.svg";
import navIcon5 from "../../assets/img/nav-icon5.svg";
import Instagram from "../../assets/img/Instagram.png";
import Image from "../../assets/img/Carousel1.jpg";
import Image2 from "../../assets/img/Carousel2.jpg";
import Image3 from "../../assets/img/Carousel3.jpg";
import Image4 from "../../assets/img/Carousel4.jpg";

class NavbarManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clicked: false,
      windowWidth: window.innerWidth,
      scrolled: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleWindowSizeChange = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  handleScroll() {
    const { scrolled } = this.state;
    const isScrolled = window.scrollY > 0;

    if (isScrolled !== scrolled) {
      this.setState({ scrolled: isScrolled });
    }
  }

  handleClick = () => {
    this.setState((prevState) => ({ clicked: !prevState.clicked }));
  };

  render() {
    const { clicked, windowWidth, scrolled } = this.state;
    const isMobile = windowWidth <= 768;

    return (
      <nav className={`nav-items ${scrolled ? "scrolled" : ""}`}>
        <div className="LogoMetacode">
          <Link to="/">
            <img
              src={LogoMetacode}
              alt="BARBER-MAP"
              className="image-logo-metacode"
            ></img>
          </Link>
        </div>

        <span className="navbar-text">
          <div className="social-icon">
            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7090171525525299200?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7090171525525299200%29">
              <img src={navIcon1} alt="LinkedIn Icon" />
            </a>
            <a href="https://wa.me/3512035657?text=Quiero%20asesoramiento">
              <img src={navIcon5} alt="WhatsApp Icon" />
            </a>
            <a href="https://www.instagram.com/metacodeargentina/">
              <img src={Instagram} alt="WhatsApp Icon" />
            </a>
          </div>
          <a
            href="https://wa.me/3512035657?text=Quiero%20asesoramiento
"
          >
            <button>
              <span>Contáctate</span>
            </button>
          </a>
        </span>

        <div className="menu-icons" onClick={this.handleClick}>
          <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>

        <ul className={clicked ? "nav-menu active" : "nav-menu"}>
          {isMobile ? (
            <>
              <li className="item-navbar">
                <Link className="nav-links-mobile" to="/">
                  <i className="fas fa-home icon-navbar"></i> Inicio
                </Link>
              </li>
              <li className="item-navbar">
                <Link className="nav-links-mobile">
                  <i className="fas fa-cogs icon-navbar"></i> Skills
                </Link>
                <ul className="submenu-drop2">
                  <li className="item-submenu">
                    <Link to="/services">
                      <i class="fa-solid fa-network-wired icon-submenu"></i>
                      Servicios
                    </Link>
                  </li>
                  <li className="item-submenu">
                    <Link to="/distinciónexperiencia">
                      <i class="fa-solid fa-arrow-up-right-dots icon-submenu"></i>
                      Distincion y experiencia
                    </Link>
                  </li>
                  <li className="item-submenu">
                    <Link to="/innovation">
                      <i class="fa-regular fa-lightbulb icon-submenu"></i>
                      Innovación
                    </Link>
                  </li>
                  <li className="item-submenu">
                    <Link to="/industries-served">
                      <i class="fa-solid fa-industry icon-submenu"></i>
                      Industrias atendidas
                    </Link>
                  </li>
                  <li className="item-submenu">
                    <Link to="/technologies">
                      <i class="fa-solid fa-globe icon-submenu"></i>Tecnologías
                      utlizadas
                    </Link>
                  </li>
                  {/* <li className="item-submenu">
                    <Link>
                      <i class="fa-solid fa-person-running icon-submenu"></i>
                      Casos de éxito
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li className="item-navbar">
                <Link className="nav-links-mobile">
                  <i className="fas fa-project-diagram icon-navbar"></i>{" "}
                  Proyectos
                </Link>
                <ul className="submenu-drop">
                  <li className="item-submenu2">
                    <Link to="/projects">
                      <i className="fas fa-users icon-navbar" />
                      Nuestros Clientes
                    </Link>
                  </li>
                  <li className="item-submenu2">
                    <Link to="/app-barbershop">
                      <i className="fas fa-cut icon-navbar" />
                      Aplicación web para barberías
                    </Link>
                  </li>
                  <li className="item-submenu2">
                    <Link to="/app-photography">
                      <i className="fas fa-camera icon-navbar" />
                      Aplicación web para fotógrafos
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="item-navbar">
                <Link className="nav-links-mobile">
                  <i className="fas fas fa-cube icon-navbar"></i> Productos
                </Link>
                <ul className="submenu-drop">
                  <li className="item-submenu2">
                    <Link to="/plans">
                      <i class="fa-solid fa-list icon-navbar"></i>
                      Planes
                    </Link>
                  </li>
                  <li className="item-submenu2">
                    <Link to="https://barbermap.com.ar/">
                      <i className="fas fa-cut icon-navbar" />
                      BarberMap
                    </Link>
                  </li>
                  <li className="item-submenu2">
                    <Link>
                      <i className="fas fa-camera icon-navbar" />
                      CapturePro events
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="item-navbar">
                <Link className="nav-links-mobile">
                  <i className="fas fa-building icon-navbar"></i> Empresa
                </Link>
                <ul className="submenu-drop2">
                  <li className="item-submenu">
                    <Link>
                      <i class="fa-solid fa-timeline icon-submenu"></i>
                      Historia
                    </Link>
                  </li>
                  <li className="item-submenu">
                    <Link>
                      <i class="fa-solid fa-earth-americas icon-submenu"></i>
                      Mision y Vision
                    </Link>
                  </li>
                  <li className="item-submenu">
                    <Link>
                      <i class="fa-solid fa-people-group icon-submenu"></i>
                      Valores
                    </Link>
                  </li>
                  <li className="item-submenu">
                    <Link>
                      <i class="fa-solid fa-envelope icon-submenu"></i>
                      Contacto
                    </Link>
                  </li>
                  <li className="item-submenu">
                    <Link>
                      <i class="fa-solid fa-bullhorn icon-submenu"></i>Noticias
                      y actualizaciones
                    </Link>
                  </li>
                </ul>
              </li>
            </>
          ) : (
            <>
              <li className="item-navbar">
                <Link className="nav-links" to="/">
                  Inicio
                </Link>
              </li>
              <li className="item-navbar">
                <Link className="nav-links">Skills</Link>
                <ul id="submenu" className="submenu-drop-2">
                  <div className="submenu-left">
                    <img src={Image} alt="Metacode" className="col-6" />
                  </div>
                  <div className="submenu-items col-6">
                    <li>
                      <Link to="/services">Servicios</Link>
                    </li>
                    <br />
                    <li>
                      <Link to="/distinciónexperiencia">
                        Distinción y Experiencia
                      </Link>
                    </li>
                    <br />
                    <li>
                      <Link to="/innovation">Innovación</Link>
                    </li>
                    <br />
                    <li>
                      <Link to="/industries-served">Industrias Atendidas</Link>
                    </li>
                    <br />
                    <li>
                      <Link to="/technologies">Tecnologías Utilizadas</Link>
                    </li>
                    <br />
                    {/* <li>
                      <Link>Casos de Éxito</Link>
                    </li> */}
                  </div>
                </ul>
              </li>
              <li className="item-navbar">
                {" "}
                <Link className="nav-links">Proyectos</Link>
                <ul id="submenu" className="submenu-drop">
                  <div className="submenu-left">
                    <img src={Image2} alt="Metacode" className="col-6" />
                  </div>
                  <div className="submenu-items col-6">
                    <li>
                      <Link to="/projects">Nuestros clientes</Link>
                    </li>
                    <br />
                    <li>
                      <Link to="/app-barbershop">
                        Aplicación web para barberías
                      </Link>
                    </li>
                    <br />
                    <li>
                      <Link to="/app-photography">
                        Aplicación web para fotógrafos
                      </Link>
                    </li>
                    <br />
                  </div>
                </ul>
              </li>
              <li className="item-navbar">
                <Link className="nav-links">Productos</Link>
                <ul id="submenu" className="submenu-drop-3">
                  <div className="submenu-left">
                    <img src={Image4} alt="Metacode" className="col-6" />
                  </div>
                  <div className="submenu-items col-6">
                    <li>
                      <Link to="/plans">Planes</Link>
                    </li>
                    <br />
                    <li>
                      <Link to="https://barbermap.com.ar/">Barber Map</Link>
                    </li>
                    <br />
                    <li>
                      <Link>CapturePro Events</Link>
                    </li>
                  </div>
                </ul>
              </li>
              <li className="item-navbar">
                <Link className="nav-links">Empresa</Link>
                <ul id="submenu" className="submenu-drop-4">
                  <div className="submenu-left">
                    <img src={Image3} alt="Metacode" className="col-6" />
                  </div>
                  <div className="submenu-items col-6">
                    <li>
                      <Link>Historia</Link>
                    </li>
                    <br />
                    <li>
                      <Link>Misión y Visión</Link>
                    </li>
                    <br />
                    <li>
                      <Link>Valores</Link>
                    </li>
                    <br />
                    <li>
                      <Link>Contacto</Link>
                    </li>
                    <br />
                    <li>
                      <Link>Noticias y Actualizaciones</Link>
                    </li>
                  </div>
                </ul>
              </li>
            </>
          )}
          <hr className="divider" />
          <span className="navbar-text-2">
            <div className="social-icon">
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7090171525525299200?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7090171525525299200%29">
                <img src={navIcon1} alt="LinkedIn Icon" />
              </a>
              <a href="https://wa.me/3512035657">
                <img src={navIcon5} alt="WhatsApp Icon" />
              </a>
              <a href="https://www.instagram.com/metacodeargentina/">
                <img src={Instagram} alt="WhatsApp Icon" />
              </a>
            </div>
            <Link to="">
              <button className="button-connect">
                <span>Conectate</span>
              </button>
            </Link>
          </span>
        </ul>
      </nav>
    );
  }
}

export default NavbarManagement;
