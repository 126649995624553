import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../../styles/Slider.css"
import Image from "../../assets/img/logo-simcor.png"
import Image2 from "../../assets/img/naiken.png"
import Image3 from "../../assets/img/LogoBarber.png"

const Carousel = () => {
  const companies = [
    { id: 1, image: Image},
    { id: 2, image: Image2 },
    { id: 3, image: Image3 },
    { id: 1, image: Image},
    { id: 2, image: Image2 },
    { id: 3, image: Image3 },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <Slider {...settings}>
      {companies.map((company) => (
        <div key={company.id} className='container-slider'>
          <img src={company.image} alt={`Empresa ${company.id}`} />
        </div>
      ))}
    </Slider>
  );
};

export default Carousel;
